module.exports = {
  prevPanel() {
    console.log('prevPanel',this.activePanel)
    if (this.welcomeActive) {
      console.log('welcomeActive',this.activePanel)
      if (this.activePanel === 0) {
        return;
      } else {
        this.activePanel--;
      }
      this.$refs.welcome.prevPanel();
    } else {
      console.log('welcomeNotActive',this.activePanel)
      let tutorialIndex = this.intros.indexOf(true);
      this.buttonActive = false;
      this.activePanel--;
      this.$refs["tut" + tutorialIndex].prevPanel();
    }
  },
  nextPanel() {
    console.log('nextPanel',this.activePanel)
    if (this.welcomeActive) {
      console.log('welcomeActive',this.activePanel)
      this.buttonActive = false;
      this.$refs.welcome.goNext();
      if (this.activePanel === 1) {
        setTimeout(() => {
          this.activePanel++;
        }, 550);
      } else {
        this.activePanel++;
      }
    } else {
      console.log('welcomeNotActive',this.activePanel)
      let tutorialIndex = this.intros.indexOf(true);
      this.buttonActive = false;
      this.activePanel++;
      this.$refs["tut" + tutorialIndex].nextPanel();
    }
  },
  activateButton() {
    this.buttonActive = true;
  },
  goBack() {
    console.log('goBack',this.activePanel)
    this.headerActive = false;
    this.$set(this.intros, 0, false);
    this.activePanel = 0;
    setTimeout(() => {
      this.welcomeActive = true;
      setTimeout(() => {
        this.tutKeys[0]++;
      }, 0);
    }, 550);
  },
  welcomePassed() {
    console.log('welcomePass',this.activePanel);
    this.activePanel = 0;
    this.welcomeActive = false;
    setTimeout(() => {
      this.activePanel = 0;
      // change this to 0 when you want to start from the beginning
      this.$set(this.intros, 0, true);
      // this.tutKeys[0]++;
    }, 550);
  },
  partComplete(index) {
    console.log('partComplete',this.activePanel);
    this.activePanel = 0;
    this.testAreaActive = false;
    setTimeout(() => {
      this.headerActive = true;
      this.footerActive = true;
      this.$set(this.intros, index + 1, true);
      // this.intros[index + 1] = true;
    }, 550);
  },
  tutorialDone(tutorialNumber) {
    this.headerActive = false;
    this.footerActive = false;
    this.footer = false;
    console.log('tutorialDone',this.activePanel,'tutorialNumber',tutorialNumber)
    this.activePanel = 0;
    this.$set(this.intros, tutorialNumber, false);
    // this.intros[tutorialNumber] = false;
    setTimeout(() => {
      this.testAreaActive = true;
      this.idk = tutorialNumber + 1;
    }, 500);
  },
  refresh(index) {
    console.log('refresh',this.activePanel);
    this.activePanel = 0;
    this.$set(this.intros, index, false);
    setTimeout(() => {
      this.$set(this.intros, index, true);
    }, 550);
  },
};
