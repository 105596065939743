<template>
  <div>
    <!-- <transition appear name="tpopup">
      <div v-if="panel == 0" class="info info-one">
        <img src="@/assets/genova.svg" />
      </div>
    </transition> -->
    <transition appear name="tpopup">
      <div v-if="panel == 0" class="info info-one">
        <img src="@/assets/dikogbtlogo.svg" width="600" />
      </div>
    </transition>
    <!-- <transition appear name="tpopup">
      <div v-if="panel == 2" class="info info-one">
        <img src="@/assets/gbt.svg" />
      </div>
    </transition> -->
    <transition appear name="tpopup">
      <div class="" v-if="panel == 1">
        <b-row align-h="center" class="mt-5">
          <h1>Hoşgeldiniz</h1>
        </b-row>
        <!-- <b-row align-h="center" class="mt-3">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          :variant="countDown == '' ? 'primary' : 'outline-secondary'"
          :disabled="countDown == '' ? false : true"
          @click="$emit('welcomePassed')"
        >
          {{ countDown == "" ? "Teste Başla" : countDown }}
        </b-button>
      </b-row> -->
        <!-- <b-row class="mt-3">
          <div class="headset" @click.prevent="playSound">
            <img src="@/assets/headset.png" alt="" width="96" />
          </div>
        </b-row> -->
      </div>
    </transition>
    <transition appear name="tpopup">
      <div v-if="panel == 2" class="info info-one">
        <div class="two-circle">
          <h1 class="title two">4</h1>
        </div>
        <h1 class="title title-b">Bölümden Oluşuyor</h1>
      </div>
    </transition>
  </div>
</template>
<script>
import { BButton, BCard, BCol, BRow } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BButton,
    BCard,
    BCol,
    BRow,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      countDown: 3,
      panel: 0,
      btnTimes: [1000, 1000, 1000, 1000, 1000],
    };
  },
  mounted() {
    // setTimeout(() => {
    //   this.startButton = true;
    // }, 5000);
    // this.startCountDown();
    // let nextPageInterval = setInterval(() => {
    //   this.goNext();
    // }, 2000);
  },
  methods: {
    playSound() {
      const audio = new Audio(require("@/assets/volumecheck.wav"));
      console.log("in play");
      audio.play();
    },
    startCountDown() {
      this.countDown = "";
      setTimeout(() => {
        this.$emit("btnActive");
      }, 1000);
      // this.countDown = "3";
      // setTimeout(() => {
      //   this.countDown = "2";
      //   setTimeout(() => {
      //     this.countDown = "1";
      //     setTimeout(() => {
      //       this.countDown = "";
      //     }, 1000);
      //   }, 1000);
      // }, 1000);
    },
    goNext() {
      if (this.panel == 2) {
        this.$emit("welcomePassed");
        return;
      } else if (this.panel == 1) {
        setTimeout(() => {
          this.$emit("headerActivate");
          this.$emit("footerActivate");
        }, 550);
      }
      const tempPanel = this.panel + 1;
      this.panel = 99;
      setTimeout(() => {
        this.panel = tempPanel;
      }, 550);
      setTimeout(() => {
        this.$emit("btnActive");
      }, this.btnTimes[tempPanel] + 550);
    },
    prevPanel() {
      if (this.panel != 0) {
        const tempPanel = this.panel - 1;
        if (tempPanel == 2) {
          this.$emit("headerActivate");
        }
        this.panel = 99;
        setTimeout(() => {
          this.panel = tempPanel;
        }, 550);
        setTimeout(() => {
          this.$emit("btnActive");
        }, this.btnTimes[tempPanel] + 550);
      }
    },
  },
};
</script>
<style scoped>
/* .tpopup-leave-from {
  transform: scale(1);
}

.tpopup-leave-to {
  transform: scale(0);
}

.tpopup-leave-active {
  transition: all 0.5s ease;
} */
.tpopup-enter-active {
  animation: slidein 0.5s ease;
}

.tpopup-leave-active {
  animation: slideout 0.5s ease;
}

/* @keyframes slideout {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }

} */

@keyframes slidein {
  0% {
    /* transform: translateX(-100%); */
    transform: scale(0);
  }

  100% {
    /* transform: translateX(0); */
    transform: scale(1);
  }
}

@keyframes slideout {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

.button-enter-active {
  animation: button-anim 0.5s ease-in-out;
}

@keyframes button-anim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

body {
  text-align: center;
}

.container {
  margin: 1rem;
}

.headset {
  position: absolute;
  right: 5%;
  bottom: 5%;
  /* width: 120px;
  height: 120px; */
  /* background */
  /* rgb(242, 5, 203)
    rgb(5, 199, 242)
  */
  background: rgb(184, 48, 136);
  background: linear-gradient(
    45deg,
    rgba(184, 48, 136, 1) 0%,
    rgba(52, 188, 229, 1) 100%
  );
  padding: 2rem;
  border-radius: 50%;
  display: flex;
  /* box shadow */
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.8);

  transition: all 1s ease;
}

img {
  transition: all 0.5s ease;
}

.headset:hover > img {
  transform: scale(1.2);

  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  cursor: pointer;
}

.headset:hover {
  transform: scale(1.05);

  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  cursor: pointer;
}

.start-message {
  position: absolute;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  font-size: 2em;
  color: #fff;
  font-weight: bold;
  text-align: center;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.message-box {
  padding: 1rem;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
  z-index: 999;
  width: 721.78px;
  height: 775px;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
}

/* CSS */
.button-71 {
  background-color: rgba(5, 199, 242, 1);
  border: 0;
  border-radius: 56px;
  color: #fff;
  cursor: pointer;
  display: inline-block;

  font-size: 18px;
  font-weight: 600;
  outline: 0;
  padding: 16px 21px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-71:before {
  background-color: initial;
  background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 125px;
  content: "";
  height: 50%;
  left: 4%;
  opacity: 0.5;
  position: absolute;
  top: 0;
  transition: all 0.3s;
  width: 92%;
}

.button-71:hover {
  box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px,
    rgba(0, 0, 0, 0.1) 0 10px 13px;
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .button-71 {
    padding: 16px 48px;
  }
}

.title {
  color: #e9ecef !important;
}

.title-a,
.title-b {
  color: #e9ecef !important;
  margin: 20px;
  font-size: 5rem;
  text-align: center;
}

.title-c {
  color: #e9ecef !important;
  margin: 20px;
  font-size: 3rem;
}

.two {
  font-size: 8rem;
  color: #fff;
  margin-bottom: 1rem;
}

.two-circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: rgb(184, 48, 136);

  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}
</style>
