<template>
  <div class="container">
    <div class="progress progress-striped">
      <div class="progress-bar" :class="{ pauseBar: pauseBarAnimation }" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      /* pauseBar: false, */
    };
  },
  props: {
    pausedState: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: Boolean,
      default: "false",
    },
    example: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    pauseBarAnimation() {
      if (this.transition) return true;
      return this.pausedState;
    },
    timertime() {
      return this.example ? "15s" : "30s";
    },
  },
};
</script>
<style scoped lang="scss">
.pauseBar {
  animation-play-state: paused !important;
}
// $background: #2c303a;

.container {
  /* width: 500px; */
  text-align: center;
}

.progress {
  padding: 6px;
  height: 30px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar {
  height: 18px;
  background-color: #ee303c;
  border-radius: 4px;

  animation-timing-function: linear !important;
  transition-property: width, background-color;
}

.progress-striped .progress-bar {
  background-color: #fcbc51;
  width: 100%;
  background-image: linear-gradient(
    45deg,
    rgb(252, 163, 17) 25%,
    transparent 25%,
    transparent 50%,
    rgb(252, 163, 17) 50%,
    rgb(252, 163, 17) 75%,
    transparent 75%,
    transparent
  );
  animation: progressAnimationStrike 30s linear;
}

@keyframes progressAnimationStrike {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
</style>
